import React, { useState } from 'react';
import { useInfiniteApiQuery } from 'hooks/common';
import { Comment, fetchReplies } from 'services/api-content';
import { useQueryClient } from 'react-query';
import {
  useDeferFlagsMutation,
  useDeleteCommentMutation,
} from 'hooks/content/comment-actions';
import { flaggedCountQueryKey } from 'hooks/posts-metadata';
import { useFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';

import { CommentItem } from './CommentItem';
import styles from './comments-modal-v2.module.css';

type CommentProps = {
  comment: Comment;
  isFiltered: boolean;
  commentAttachmentsEnabled: boolean;
  nestedRepliesEnabled?: boolean;
};

export const Comments: React.FC<CommentProps> = ({
  comment,
  isFiltered,
  commentAttachmentsEnabled,
  nestedRepliesEnabled,
}) => {
  const { id, programId, contentId, replyCount } = comment;
  const [showReplies, setShowReplies] = useState<boolean>(false);

  const [selectedFilters] = useFilterDropdownQueryString('filters');

  const queryClient = useQueryClient();
  const invalidateReplies = React.useCallback(() => {
    if (comment.replyToId) {
      queryClient.invalidateQueries({
        queryKey: [
          'comment_replies',
          {
            contentId: comment.contentId,
            programId: comment.programId,
            commentId: comment.replyToId,
            pageSize: 10,
          },
        ],
      });
    } else {
      queryClient.invalidateQueries({
        queryKey: [
          'comment_posts',
          {
            contentId: comment.contentId,
            programId: comment.programId,
            filters: selectedFilters,
            pageSize: 10,
          },
        ],
      });
    }
  }, [queryClient, selectedFilters, comment]);

  const invalidateFlaggedCount = React.useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: flaggedCountQueryKey(programId),
      }),
    [queryClient, programId]
  );

  const toggleShowReplies = () => {
    setShowReplies((prev) => !prev);
  };

  const { data, hasNextPage, refetch, fetchNextPage } = useInfiniteApiQuery(
    'comment_replies',
    fetchReplies,
    {
      contentId,
      programId,
      commentId: id,
      pageSize: 10,
    },
    showReplies
  );

  const onSuccessfulMutation = React.useCallback(() => {
    invalidateReplies();
    invalidateFlaggedCount();
  }, [invalidateReplies, invalidateFlaggedCount]);

  const {
    mutate: deleteComment,
    isLoading: isDeleting,
    activeId: deleteId,
  } = useDeleteCommentMutation({
    onSuccess: onSuccessfulMutation,
  });
  const {
    mutate: deleteFlags,
    isLoading: isDeletingFlag,
    activeId: deferId,
  } = useDeferFlagsMutation({
    onSuccess: onSuccessfulMutation,
  });

  const replies = data.map((reply) => reply.attributes).flat();

  const getRepliesLabel = () => {
    if (comment.replyCount > 0) {
      if (showReplies) {
        return `Hide ${data.length > 1 ? 'replies' : 'reply'}`;
      }

      const repliesCount = replyCount ?? 0;
      return `Show ${repliesCount} ${repliesCount > 1 ? 'replies' : 'reply'}`;
    }

    return '';
  };

  let showMoreRepliesLabel = '';

  if (replies && hasNextPage) {
    showMoreRepliesLabel = 'Show more replies';
  }

  const repliesLabel = getRepliesLabel();

  return (
    <div>
      <CommentItem
        key={comment.id}
        comment={comment}
        deleteFlags={(flagsData) => {
          if (deleteFlags) {
            deleteFlags(flagsData);
            refetch?.();
          }
        }}
        deleteComment={(commentData) => {
          deleteComment(commentData);
          refetch?.();
        }}
        isDeleting={isDeleting}
        deleteId={deleteId}
        deferId={deferId}
        isDeletingFlag={isDeletingFlag}
        isFiltered={isFiltered}
        commentAttachmentsEnabled={commentAttachmentsEnabled}
      />
      {!isFiltered && (
        <>
          <div
            aria-hidden="true"
            onClick={(e) => {
              e.stopPropagation();
              toggleShowReplies();
            }}
          >
            <div className={styles.repliesLabel}>{repliesLabel}</div>
          </div>
          <>
            {showReplies && (
              <div className={styles.repliesBox}>
                {replies && replies.length
                  ? replies.map((reply) => (
                      <>
                        {nestedRepliesEnabled && reply.replyCount > 0 ? (
                          <Comments
                            key={reply.id}
                            comment={reply}
                            isFiltered={isFiltered}
                            commentAttachmentsEnabled={
                              commentAttachmentsEnabled
                            }
                          />
                        ) : (
                          <CommentItem
                            key={reply.id}
                            comment={reply}
                            deleteFlags={(flagsData) => {
                              if (deleteFlags) {
                                deleteFlags(flagsData);
                                refetch?.();
                              }
                            }}
                            deleteComment={(commentData) => {
                              deleteComment(commentData);
                              refetch?.();
                            }}
                            isDeleting={isDeleting}
                            deleteId={deleteId}
                            deferId={deferId}
                            isDeletingFlag={isDeletingFlag}
                            isFiltered={isFiltered}
                            commentAttachmentsEnabled={
                              commentAttachmentsEnabled
                            }
                          />
                        )}
                      </>
                    ))
                  : null}
              </div>
            )}
          </>
          <>
            {hasNextPage && (
              <div
                aria-hidden="true"
                onClick={(e) => {
                  e.stopPropagation();
                  fetchNextPage();
                }}
              >
                <div className={styles.repliesLabel}>
                  {showMoreRepliesLabel}
                </div>
              </div>
            )}
          </>
        </>
      )}
    </div>
  );
};
