import React from 'react';
import { Box } from 'DesignSystem/Components';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Body, color } from 'DesignSystem/Typography';
import { useNotificationCenterEnabled } from 'hooks/notification-center';
import { useJourneyMetricsV2Enabled } from 'hooks/journeyMetricsV2';
import styles from './metrics.module.css';

export const Metrics: React.FC<{ isLoading: boolean }> = ({
  isLoading,
  children,
}) => {
  const notificationCenterEnabled = useNotificationCenterEnabled();
  const {
    enabled: journeyMetricsV2Enabled,
    isLoading: journeyMetricsV2Loading,
  } = useJourneyMetricsV2Enabled();
  const combinedIsLoading = isLoading || journeyMetricsV2Loading;

  const contents =
    notificationCenterEnabled || journeyMetricsV2Enabled
      ? children
      : // only fallback to UndefinedMetrics in non-omnichannel cases
        children ?? <UndefinedMetrics />;
  return (
    <Box width="100%">
      <Box margin={[16, 0]}>
        <hr />
      </Box>
      {combinedIsLoading && <LoadingSpinner size="medium" />}
      {!combinedIsLoading && <div>{contents}</div>}
    </Box>
  );
};

export const Metric: React.FC<{
  label: string;
  value?: number;
}> = ({ label, value }) => {
  return (
    <Box key={label} className={styles.metric}>
      <span>
        <Body>{label}: </Body>
        <Body semibold>{value}</Body>
      </span>
      {/* {rate !== undefined && <Body semibold>&nbsp;({rate}%)</Body>} */}
    </Box>
  );
};

const UndefinedMetrics = () => (
  <Body color={color.gray60}>Metrics are available 24 hours after publish</Body>
);
